import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";
// 考试科目
export const getSubList = params => {
  return getRequest("/yethan/register/subject/listPage", params);
};
// 添加科目
export const addSubject = params => {
  return postRequest("/yethan/register/subject", params);
};
// 修改科目
export const editSubject = params => {
  return putRequest("/yethan/register/subject", params);
};
// 删除科目
export function deleteSubject(sid) {
  return deleteRequest("/yethan/register/subject/"+sid);
}
// 更新科目状态
export const updateSubjectQyzt = params => {
  return patchRequest("/yethan/register/subject/updateSubjectQyzt", params);
};
// 通过名称查找
export const getSubListByName = params => {
  return getRequest("/yethan/register/subject/byName", params);
};


// 科目查询
export const getSubListEasy = params => {
  return getRequest("/yethan/register/subject/easyList", params);
};

// 导出excel
export function exportSubject(data={}) {
    return downloadRequest('/yethan/register/subject/exportExcel',data);
}
// 导出dbf
export function exportSubjectDbf(data={}) {
    return downloadRequest('/yethan/register/subject/exportDbf',data);
}