<!-- 科目 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getSubList,
    updateSubjectQyzt,
    deleteSubject,
    addSubject,
    editSubject,
  } from "@/api/admin/base/subject.js"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"


  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        checked: true,
        exportTitle: "考试科目导出",
        importTitle: "考试科目导入",
        defaultvalue: 1,
        currentpage: 1,
        showmodal: false,
        tableList: [],
        dialogTitle: "添加考试科目",
        module: "KSKM",
        disabled: false,
        addForm: {
          lbmc: "",
          flbm: "",
          lbbm: "",
          flmc: "",
          kmdm: "",
          kmmc: "",
          fz: 100,
          sfbz: "",
          qyzt: true,
          cksm: "",
        },
        classList: [],
        select: {
          keyParam: "kmmc",
        },
        secondList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }

      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteSubject(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        getSubList(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateSubjectQyzt({
          sid: sid,
          qyzt: qyzt
        }).then()
      },
      // 添加类别
      addMaintype() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sfbz = this.addForm.sfbz * 100,
          addSubject(formDate).then(res => {
            if (res.status) {
              this.success()
            }

          })
      },
      editMaintype() {
        this.dialogTitle = "修改考试科目"
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sfbz = this.addForm.sfbz * 100,
          formDate.sid = this.addForm.sid
        editSubject(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          kmdm: "",
          kmmc: "",
          fz: 100,
          sfbz: "",
          qyzt: true,
          cksm: "",
        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加考试科目"
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          kmdm: "",
          kmmc: "",
          fz: 100,
          sfbz: "",
          qyzt: true,
          cksm: "",
        }
        this.getBm(this.module, "km").then(e => {
          this.addForm.kmdm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.addForm.sfbz = this.addForm.sfbz / 100
        this.showmodal = true
        this.dialogTitle = "修改考试科目"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editMaintype()
        } else {
          this.addMaintype()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList()
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList({
          lbbm: params
        }).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      // 条件查询
      getlistByParam() {
        getSubList({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      getflmc() {
        let val = this.addForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.addForm.flmc = obj.flmc
      },

      getlbmc() {
        let val = this.addForm.lbbm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.addForm.lbmc = obj.lbmc
        this.getSecondList(
          val
        );
      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }
    },
    mounted() {
      this.getList()
      this.getSecondList()
      this.getClassListPage()
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearablestyle="min-width: 15%;" placeholder="请选择考试类别"
                  v-model="select.lbbm" size="small" @change="getSecondList(select.lbbm)">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.value" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 blueBorder" clearable style="min-width: 10%;" placeholder="请选择考试二级类别"
                  v-model="select.flbm" size="small">
                  <el-option :value="item.flbm" v-for="item in secondList" :key="item.value" :label="item.flmc">
                    {{item.flmc}}
                  </el-option>
                </el-select>
                <input placeholder="关键字" v-model="select.keyWord" class="h30 form-control border-blue mr-2 w-10"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加科目</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module: module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th style="width:15%">考试类别</th>
                    <th>考试二级类别</th>
                    <th style="width:5%">科目代码</th>
                    <th>科目名称</th>
                    <th style="width:8%">导出科目代码</th>
                    <th style="width:5%">分制</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width:10%">参考书目</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.lbmc}}</td>
                    <td><a href="/admin/baseSecondType" class="font-blue">{{obj.flmc}}</a></td>
                    <td>{{obj.kmdm}}</td>
                    <td>{{obj.kmmc}}</td>
                    <td>{{obj.dczydm}}</td>
                    <td> {{obj.fz}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.cksm}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click="editItem(obj)"></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.kmmc)"></i></td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>

            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加科目 -->
    <b-modal id="addSubject" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>考试类别：</label>
              <div class=" col-sm-10 p-0 flexList">
                <select name="lbbm" class="form-control form-select w-100" v-model="addForm.lbbm" @change="getlbmc">
                  <option value="" disabled>请选择所属类别</option>
                  <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
                </select>

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属分类：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.flbm" @change="getflmc">
                  <option value="" disabled>请选择分类</option>
                  <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目代码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" maxlength="10" :disabled="disabled" v-model="addForm.kmdm" placeholder="系统唯—"
                  class="form-control w-100 h30 " />

              </div>
            </div>
            <div class="d-flex check-distri mb-3"><label>科目名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" maxlength="50" v-model="addForm.kmmc" placeholder="科目名称"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>单科满分：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" v-model="addForm.fz" placeholder="100"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>收费标准：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input value="" type="text" v-model="addForm.sfbz" class="w-100 h30" placeholder="0.00">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>科目代码<br>（导出）：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" maxlength="10" value="" v-model="addForm.dczydm" placeholder="导出科目代码"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>参考书目：</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols="" class="form-control" v-model="addForm.cksm" placeholder="多行输入"
                  maxlength="200" style="overflow-y: auto;"></textarea>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="exampleRadios" id="exampleRadios1"
                    value=true v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="exampleRadios" id="exampleRadios1" value=false
                    class="form-check-input" v-model="addForm.qyzt"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>

        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
