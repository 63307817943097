<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<style>
.page-title-box .col-sm-3 {
  max-width: 9% !important;
}
</style>
<script>
  // import $ from "jquery"
  export default {
    components: {},
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        },

      },
    },
    computed: {
      newitems() {
        return this.$store.state.layout.items;
      },
    },
    data() {
      return {
        isActive: 1,
        pathArr: [{
            index: 1,
            url: "/admin/baseBigType",
            name: "考试大类管理",
          },
          {
            index: 2,
            url: "/admin/baseSecondType",
            name: "考试二级分类",
          },
          {
            index: 3,
            url: "/admin/baseMajors",
            name: "专业级别设置",
          },
          {
            index: 4,
            url: "/admin/baseOrganization",
            name: "机构单位管理",
            children: ['baseCompany', 'baseDepartment']
          },
          {
            index: 5,
            url: "/admin/basePosition",
            name: "职位管理",
          },
          {
            index: 6,
            url: "/admin/baseSubject",
            name: "考试科目管理",
          },
          {
            index: 7,
            url: "/admin/baseMould",
            name: "考生提示模板",
          },
          {
            index: 8,
            url: "/admin/baseData",
            name: "字典数据",
            children: ['baseDataDetail']
          },
          {
            index: 9,
            url: "/admin/examFree",
            name: "免试库维护",
            children: ['general']

          }
        ]
      }
    },
    mounted() {
      this.isActive = window.location.pathname
      let name = this.$route.name
      let newActive = this.pathArr.find(k => {
        if (k.children && k.children.indexOf(name) > -1) {
          return true
        }
      })
      if (newActive) {
        this.isActive = newActive.url
      }
    }


  }
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box flexList justify-content-between">
        <div class="col-2 page-title-right" style="min-width: 230px;">
          <b-breadcrumb :items="newitems" class="m-0"></b-breadcrumb>
        </div>
        <div class="flexList col-10 p-0">
          <a :href="obj.url" class="col-sm-3 p-0 mr-2" v-for="obj in pathArr" :key="obj.index">
            <div class="base-header-box " :class="{active:isActive==obj.url}">{{obj.name}}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
